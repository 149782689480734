import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styled from 'styled-components'

const AboutHero = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "richard.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        contentfulAboutPage {
          bio {
            json
          }
        }
      }
    `}
    render={data => {
      const image = data.image.childImageSharp.fluid
      const bio = data.contentfulAboutPage.bio.json
      return (
        <BackgroundImage className={className} fluid={image}>
          <div className="about-hero-text">
            <div className="text-wrap-about">
              {documentToReactComponents(bio)}
              <div className="email-richard">
                <a
                  className="email-richard-link"
                  href="mailto:richard@richardweale.com"
                  target="_blank"
                >
                  EMAIL RICHARD
                </a>
              </div>
            </div>

            <a
              className="caminada"
              href="https://www.caminada.co.uk/index"
              target="_blank"
            >
              Photo By Alexander Caminada
            </a>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundSection = styled(AboutHero)`
  width: 100%;
  height: calc(100vh - 75px);
  min-height: 850px;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
`

export default StyledBackgroundSection

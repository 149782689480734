import React from 'react'
import Layout from '../components/Layout'
import '../styles/styles.scss'
import AboutHero from '../components/AboutHero'

const about = () => {
  return (
    <Layout>
      <AboutHero />
    </Layout>
  )
}

export default about
